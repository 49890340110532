import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link, navigate } from 'gatsby';
import loadable from '@loadable/component';

// import ReactPlayer from 'react-player/lazy';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { WaveUpHero } from '../../WaveSVGs/WaveUpHero';
import { IndustrySearch } from './IndustrySearch';
// import home from '../../../assets/home-video.mp4';
// import { Video } from './Video';

const Video = loadable(() => import('./Video'));

const useStyles = makeStyles((theme) => ({
	'@keyframes pulseBorder': {
		'0%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1)',
			opacity: '1',
		},
		'100%': {
			transform: 'translateX(-50%) translateY(-50%) translateZ(0) scale(1.5)',
			opacity: '0',
		},
	},

	heroPills: {
		margin: '0',
		marginBottom: '0px !important',
		marginTop: '10px !important',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '765px',
		maxWidth: '100%',

		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},

	heroPill: {
		maxWidth: '28%',
		padding: '0px',
		paddingTop: '0px',
		borderRadius: '8px',

		[theme.breakpoints.down('sm')]: {
			width: '50%',
			maxWidth: '50%',
			margin: '0 auto',
			marginBottom: '16px',
		},

		'&:nth-child(even)': {
			padding: '0px',
			[theme.breakpoints.down('sm')]: {
				paddingTop: '0px',
				paddingLeft: '8px',
				paddingRight: '0px',
			},
		},
		'&:nth-child(odd)': {
			padding: '0px',
			[theme.breakpoints.down('sm')]: {
				paddingTop: '0px',
				paddingLeft: '0px',
				paddingRight: '8px',
			},
		},
	},

	root: {
		position: 'relative',
		width: '100%',
		padding: '6rem 0',
		maxWidth: '100vw',
		overflow: 'hidden',

		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
		},
	},
	bgWhite: {
		width: '100%',
		height: '200%',
		// background: '#E5EAF4',
		position: 'absolute',
		top: '-43%',
		left: '-50%',
		borderRadius: '50%',
		transform: 'scaleX(-1)',
		boxShadow: '4px 4px 6px rgb(50 50 93 / 15%), 0 1px 3px rgb(0 0 0 / 12%)',
		backgroundSize: 'cover',
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	bgBlueLight: {
		width: '100%',
		height: '200%',
		background: 'rgba(5, 82, 145, 0.7)',
		position: 'absolute',
		top: '-50%',
		left: '-50%',
		borderRadius: '50%',
		marginLeft: '25%',
		boxShadow: '4px 4px 6px rgb(50 50 93 / 8%), 0 1px 3px rgb(0 0 0 / 6%)',
		[theme.breakpoints.down('sm')]: {
			// background: 'rgba(255,255,255,0)',
			top: 0,
			left: 0,
			borderRadius: '0px',
			margin: '0px',
		},
	},

	playButton: {
		height: '100px',
		width: '100px',
		background: '#fff',
		color: '#002d5c',
		fontSize: '1.5rem',
		borderRadius: '50%',
		position: 'absolute',
		left: '100%',
		marginTop: '-20px',
		marginLeft: '-50px',
		top: '44%',
		opacity: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		cursor: 'pointer',
		zIndex: 5,

		'&::before': {
			content: `''`,
			position: 'absolute',
			zIndex: '-1',
			left: '50%',
			top: '50%',
			transform: 'translateX(-50%) translateY(-50%)',
			display: 'block',
			width: '100px',
			height: '100px',
			background: '#fff',
			borderRadius: '50%',
		},

		'&:hover': {
			'& $playButtonEffect': { transform: 'scale(1.1)' },
			'&::before': {
				animation: '$pulseBorder 1500ms ease-out infinite',
			},
		},
		[theme.breakpoints.down('md')]: {
			top: '43%',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	playButtonEffect: {
		background: 'rgba(0, 45, 92,0.95)',
		padding: '130px',
		position: 'absolute',
		left: '100%',
		marginTop: '-100px',
		marginLeft: '-128px',
		top: '44%',
		borderRadius: '50%',
		mixBlendMode: 'multiply',
		boxShadow: '0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%)',
		zIndex: 5,
		transition: 'all 1s ease-in-out',
		[theme.breakpoints.down('md')]: {
			top: '43%',
		},
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},

	heroVideoBg: {
		position: 'absolute',
		left: '43%',
		width: '57%',
		height: '100%',
		top: 0,
		objectFit: 'cover',
		zIndex: -1,
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	heroImgBg: {
		// display: 'inline-block',
		position: 'absolute',
		top: -80,
		left: 165,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		zIndex: -2,
		// [theme.breakpoints.down('md')]: {
		// 	zIndex: 1,
		// },
		[theme.breakpoints.down('sm')]: {
			display: 'none',
		},
	},
	mobileHeroImgBg: {
		// display: 'inline-block',
		position: 'absolute',
		// top: -80,
		left: 0,
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		zIndex: -2,
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
	},
	heroContent: {
		textAlign: 'left',
		position: 'relative',
		left: '0',
	},
	text: {
		color: theme.workwaveBlue,
		lineHeight: '.7',
		[theme.breakpoints.down('sm')]: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'flex-start',
			flexDirection: 'column',
		},
	},
	imgCont: {
		padding: '2rem 0',
		maxWidth: '100vw',
		overflowX: 'hidden',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',

		[theme.breakpoints.down('xs')]: {
			paddingTop: '2rem',
			paddingBottom: '1rem',
		},
	},
	imgRight: {
		marginLeft: '-10rem',
		[theme.breakpoints.down('lg')]: {
			marginLeft: '-9rem',
			height: '560px',
		},
		// [theme.breakpoints.down('md')]: {
		// 	marginLeft: '-4rem',
		// 	height: '380px',
		// },
		[theme.breakpoints.down('sm')]: {
			marginLeft: '-8rem',
			height: '300px',
		},
		[theme.breakpoints.down('xs')]: {
			marginLeft: '0',
			height: '260px',
		},
	},
	pills: {
		border: '1px solid #E5EAF4',
		borderRadius: '8px',
		background: '#FFFFFF',
		display: 'flex',
		alignItems: 'center',
		transition: 'all .5s',
		'&:hover': {
			transform: 'scale(1.05)',
		},
	},
	heroHeader: {
		lineHeight: '74px',
		letterSpacing: '.2px',
		color: theme.white,
		fontWeight: 700,
		fontSize: '4.3rem',
		//marginLeft: '-33px',
		[theme.breakpoints.down('md')]: {
			fontSize: '3rem',
			lineHeight: '60px',
			textAlign: 'left',
			// marginLeft: '-15px',
			marginBottom: '1rem',
		},
		[theme.breakpoints.down('sm')]: {
			lineHeight: '45px',
			fontSize: '2.5rem',
			marginLeft: '0',
		},
		[theme.breakpoints.down('xs')]: {
			fontSize: '2rem',
		},
	},
	heroSubheader: {
		color: theme.white,
		letterSpacing: '.2px',
		lineHeight: '53px',
		fontWeight: 700,
		fontSize: '3.7813rem',
		marginTop: '10px',
		marginBottom: '16px',
		marginLeft: '5px',
		[theme.breakpoints.down('md')]: {
			marginLeft: '-6px',
			fontSize: '2.625rem',
			lineHeight: '40px',
		},
		[theme.breakpoints.down('sm')]: {
			marginLeft: '0',
		},
	},
	heroBodyContent: {
		lineHeight: 2,
		fontSize: '1.8rem',
		color: theme.white,
		// fontWeight: 700,
		textAlign: 'left',
		marginTop: '0px',
		letterSpacing: '.2px',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.6rem',
			marginBottom: '.5rem',
		},
	},

	heroSearchInput: {
		display: 'none',
	},

	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: 'none',
		marginTop: '0',
		zIndex: 10,
		borderRadius: '20px',
		overflow: 'hidden',
		[theme.breakpoints.down('md')]: {
			marginBottom: '-50px',
		},
		[theme.breakpoints.up('md')]: {
			display: 'none',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '1rem',
		},
		[theme.breakpoints.down('xs')]: {
			marginBottom: '-26px',
		},
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	heroVideoFrame: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
	},
}));

export const Hero = ({
	hero,
	industries,
	heroIndustries,
	homeHeroVideo,
	setHeroModalOpen,
}) => {
	const classes = useStyles();
	const searchRef = useRef(null);
	const [selectedSlug, setSelectedSlug] = useState(null);

	//search term change handler
	const handleChange = (e, value) => {
		const selected = industries.edges.filter(
			(industry) => industry.node.title === value
		);
		if (selected.length) {
			setSelectedSlug(selected[0].node.slug.current);
			navigate(`/industries/${selected[0].node.slug.current}`);
		} else {
			setSelectedSlug(null);
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		selectedSlug && navigate(`/industries/${selectedSlug}`);
	};

	const lg = useMediaQuery('(max-width: 1279px)');
	const md = useMediaQuery('(max-width: 960px)');

	return (
		<>
			<Grid
				container
				direction='row'
				alignItems='center'
				className={classes.root}>
				<div
					style={{
						backgroundImage: `url(${hero.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,
						backgroundPosition: 'center',
					}}
					className={classes.bgWhite}
				/>
				<div className={classes.bgBlueLight}>
					<div className={classes.playButtonEffect} />
					<div
						className={classes.playButton}
						role="button"
            tabIndex={0}
						onClick={() => setHeroModalOpen(true)}>
						<FontAwesomeIcon icon={['fas', 'play']} size='lg' />
					</div>
				</div>
				{!md ? (
					<section className={classes.heroVideoBg}>
						<video
							playsInline
							muted
							loop
							autoPlay
							poster={
								hero.videoImage?.asset?.gatsbyImageData.images.fallback.src
							}
							className={classes.heroVideoFrame}>
							<source
								src='https://documents.workwave.com/service/video/wws_homepage_video.mp4'
								type='video/mp4'
							/>
						</video>
						{/* <Video
							url='https://workwave.wistia.com/medias/gng0mudw8m'
							playing={true}
							loop={true}
							muted={true}
							width='100%'
							height='1000px'
						/> */}
					</section>
				) : null}

				{md ? (
					<GatsbyImage
						image={hero.mobileBackground?.asset?.gatsbyImageData}
						alt='home-hero'
						className={classes.mobileHeroImgBg}
					/>
				) : (
					<img
						src={hero.videoImage?.asset?.gatsbyImageData.images.fallback.src}
						alt='home-hero'
						className={classes.heroImgBg}
					/>
				)}

				<Container style={{ position: 'inherit' }}>
					<Grid
						container
						item
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						className={classes.heroContent}
						xs={12}
						md={10}>
						{hero._rawContent.map((content, index) => (
							<PortableText
								key={index}
								content={content}
								className={classes.text}
								serializers={{
									h1: ({ children }) => (
										<Grid item xs={12} md={7}>
											<Typography
												variant='h2'
												component='h1'
												className={classes.heroHeader}>
												{children}
											</Typography>
										</Grid>
									),
									h4: ({ children }) => (
										<Typography variant='h4' className={classes.heroSubheader}>
											{children}
										</Typography>
									),
									normal: ({ children }) => {
										return (
											<Grid item xs={12} md={10}>
												<Typography
													variant='body1'
													className={classes.heroBodyContent}
													role="heading"
													style={{
														marginLeft: lg ? 0 : '5px',
														marginRight: lg ? 0 : '10px',
														lineHeight: md ? 1.2 : '38px',
													}}>
													{children}
												</Typography>
											</Grid>
										);
									},
								}}
							/>
						))}{' '}
					</Grid>
					<Grid
						container
						item
						direction='column'
						justifyContent='center'
						alignItems='flex-start'
						className={classes.heroContent}
						xs={12}
						md={6}
						lg={8}>
						<IndustrySearch
							className={classes.heroSearchInput}
							industries={industries}
							handleChange={handleChange}
							handleSubmit={handleSubmit}
							searchRef={searchRef}
						/>
					</Grid>
					<Grid
						item
						container
						direction='row'
						justifyContent={md ? 'center' : 'flex-start'}
						spacing={2}
						xs={12}
						md={9}
						style={{ marginTop: '.5rem' }}
						className={classes.heroPills}>
						{industries.edges
							.filter((industry) => industry.node.primary)
							.sort((a, b) =>
								a.node.homeHeroPillTitle.localeCompare(b.node.homeHeroPillTitle)
							)
							.map((industry, index) => (
								<Grid
									item
									key={index}
									style={{ position: 'relative' }}
									className={classes.heroPill}>
									<Link
										to={`/industries/${industry.node.slug.current}`}
										style={{ textDecoration: 'none', position: 'inherit' }}>
										<div className={classes.pills}>
											<FontAwesomeIcon
												icon={['fad', industry.node.faIcon]}
												style={{
													height: '35px',
													width: '35px',
													border: `1px solid #E5EAF4`,
													background: '#E5EAF4',
													padding: '6px',
													color:
														industry.node.accentColor[0]?.hexValue ?? '#002D5C',
													borderTopLeftRadius: '6px',
													borderBottomLeftRadius: '6px',
												}}
											/>
											<Typography
												variant='h3'
												style={{
													textAlign: 'center',
													width: '100%',
													color: '#002D5C',
													fontWeight: 500,
													padding: '0 15px',
													position: 'relative',
													lineHeight: '1.1',
													fontSize: '0.9rem'
												}}>
												{industry.node.homeHeroPillTitle}
											</Typography>
										</div>
									</Link>
								</Grid>
							))}
						<Grid
							item
							style={{ position: 'relative' }}
							className={classes.heroPill}>
							<Link
								to={`/industries/`}
								style={{ textDecoration: 'none', position: 'inherit' }}>
								<div className={classes.pills}>
									<FontAwesomeIcon
										icon={['fad', 'circle-plus']}
										style={{
											height: '35px',
											width: '35px',
											border: `1px solid #E5EAF4`,
											background: '#E5EAF4',
											padding: '6px',
											color: '#002D5C',
											borderTopLeftRadius: '6px',
											borderBottomLeftRadius: '6px',
										}}
									/>
									<Typography
										variant='h3'
										style={{
											textAlign: 'center',
											width: '100%',
											color: '#002D5C',
											fontWeight: 500,
											padding: '0 15px',
											position: 'relative',
											lineHeight: '1.1',
											fontSize: '0.9rem'
										}}>
										All Industries
									</Typography>
								</div>
							</Link>
						</Grid>
					</Grid>
					{md && (
						<Grid
							container
							direction='row'
							justifyContent='center'
							alignItems='center'
							item
							xs>
							<div className={classes.playerWrapper}>
								<Video
									url={homeHeroVideo}
									className={classes.reactPlayer}
									height='100%'
									width='100%'
									controls={true}
								/>
							</div>
						</Grid>
					)}
				</Container>
				{/* <Grid item md={6}>
					<img
						className={classes.imgRight}
						src={hero.backgroundImage.asset.gatsbyImageData.images.fallback.src}
						alt='hero-image'
					/>
				</Grid> */}
				<WaveUpHero height='213' width='100%' fill='white' home={true} />
			</Grid>
		</>
	);
};
