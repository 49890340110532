import React from 'react';
import loadable from '@loadable/component';

import { Container, useMediaQuery } from '@material-ui/core';

import { Intro } from './Intro';
// import { BodyFeature } from './BodyFeature';
import { CenterWave } from './CenterWave';
import { UpperCtaCard } from './UpperCtaCard';
import { MobileFeatures } from './Mobile/MobileFeatures';

const BodyFeature = loadable(() => import('./BodyFeature'));

export const HomeBody = ({ intro, homeFeatures, background, homeUpperCta }) => {
	const med = useMediaQuery('(max-width: 960px)');
	const { ctaHeader, ctaSubheader, ctaText, ctaLink, internalLink } =
		homeUpperCta;
	return (
		<>
			<Container>
				<Intro intro={intro} />
				{!med ? (
					homeFeatures.map((feature, index) => (
						<BodyFeature
							feature={feature}
							index={index}
							key={index}
							accentColor='#002D5C'
						/>
					))
				) : (
					<MobileFeatures featureSection={homeFeatures} />
				)}
				<UpperCtaCard
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
					ctaLink={ctaLink}
					internalLink={internalLink}
				/>
			</Container>
			<CenterWave background={background} />
		</>
	);
};
